import React, { Suspense } from "react";

import { Spinner } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want

import FechGetReservationsBySearch from "../APP_RESERVATION_FECH_DATA/FechGetReservationsBySearch";

import PageCounterNew from "../components/PageCounterNew";
import BeltReservationApp from "../APP_RESERVATION_COMPONENTS/BeltReservationApp";

import queryString from "query-string";
import moment from "moment";
import ParseLinkToArray from "../utils/ParseLinkToArray";

const TabAllReservation = React.lazy(() =>
  import("../APP_RESERVATION_COMPONENTS/TabAllReservation")
);
const SearchReservationAll = React.lazy(() =>
  import("../APP_RESERVATION_FORMS/SearchReservationAll")
);

let plLocate = require("moment/locale/pl");
moment.locale("pl", plLocate);

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PageReservationHistory extends React.Component {
  TRAINER_SELECT_DESC = "Wybierz trenera";
  DATE_SELECT_DESC = "Wybierz datę";
  CLASS_SELECT_DESC = "Wybierz szkółke";

  urlDeafulValues = [
    this.TRAINER_SELECT_DESC,
    this.CLASS_SELECT_DESC,
    this.DATE_SELECT_DESC,
  ];

  urlValue = "";

  postData = {
    clientId: "",
    dateStart: "",
    dateStop: "",
    hourStart: "",
    hourStop: "",
    dayOfWeek: "",
    trainer: "",
    courtId: "",
    reservationType: "",
    promotion: "",
    className: "",
    status: "",
    sortBy: "",
    searchDirection: "",
    splitPayment: "",
    ids: [],
    page: "0",
  };

  constructor(props) {
    super(props);

    this.state = {
      title: "RezerwujKort.pl - Rezerwację",
      datePickerIsOpen: false,
      previousDay: "",
      refreshReservation: "",
      restReservationData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
    };
  }

  async componentDidMount() {
    document.title = "Rezerwacje - RezerwujKort.pl";
    registerLocale("pl", pl); // register it with the name you want
    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.postData = await ParseLinkToArray(
      this.urlValue,
      this.postData,
      this.urlDeafulValues
    );

    this.setState({
      restReservationData: await FechGetReservationsBySearch(
        this.props.match.params.club,
        search
      ),
    });
  }

  render() {
    var loader = "";
    if (!this.state.restReservationData.successful) {
      loader = (
        <div id="loader_div">
          <Spinner animation="border" />
        </div>
      );
      totalRecords = this.state.restReservationData.data;
    }

    var totalRecords = "";
    var tabAllReservation = "";
    var pageCounter = "";
    var searchPanel = "";
    if (this.state.restReservationData.successful) {
      totalRecords = this.state.restReservationData.data.totalRecordsCount;

      tabAllReservation = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <TabAllReservation
              club={this.props.match.params.club}
              restLessonsData={
                this.state.restReservationData.data.reservationResources
              }
            />
          </Suspense>
        </div>
      );

      const urlValues = new Map();
      urlValues.set("clientId", this.postData.clientId);
      urlValues.set("dateStart", this.postData.dateStart);
      urlValues.set("dateStop", this.postData.dateStop);
      urlValues.set("hourStart", this.postData.hourStart);
      urlValues.set("hourStop", this.postData.hourStop);
      urlValues.set("dayOfWeek", this.postData.dayOfWeek);
      urlValues.set("trainer", this.postData.trainer);
      urlValues.set("courtId", this.postData.courtId);
      urlValues.set("reservationType", this.postData.reservationType);
      urlValues.set("promotion", this.postData.promotion);
      urlValues.set("className", this.postData.className);
      urlValues.set("status", this.postData.status);
      urlValues.set("sortBy", this.postData.sortBy);
      urlValues.set("searchDirection", this.postData.searchDirection);
      urlValues.set("splitPayment", this.postData.splitPayment);
      urlValues.set("ids", this.postData.ids);
      urlValues.set("page", "replace");

      pageCounter = (
        <PageCounterNew
          elementCount={totalRecords}
          urlValues={urlValues}
          urlDeafulValues={this.urlDeafulValues}
          elementOnPage={this.state.restReservationData.data.recordsOnPage}
          currentPage={parseInt(this.postData.page) + 1}
          searchName={"search"}
          linkTo={
            "reservation_app/" +
            this.props.match.params.club +
            "/historia_rezerwacji"
          }
        />
      );

      searchPanel = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <SearchReservationAll
              urlValue={this.postData}
              club={this.props.match.params.club}
            />
          </Suspense>
        </div>
      );
    }

    return (
      <div>
        <div id="content">
          <BeltReservationApp club={this.props.match.params.club} />

          {searchPanel}

          <br />
          <br />
          <b>Lista wszystkich rezerwacji: ({totalRecords}):</b>
          <br />
          <br />

          {loader}

          {tabAllReservation}

          {pageCounter}
        </div>
      </div>
    );
  }
}
export default PageReservationHistory;
