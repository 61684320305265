import React from "react";

import { registerLocale } from "react-datepicker";
import { Spinner } from "react-bootstrap";
import Belt from "../components/Belt";
import BeltLogged from "../components/BeltLogged";
import queryString from "query-string";
import pl from "date-fns/locale/pl"; // the locale you want

import Footer from "../components/Footer";
import FechGetReservationStatusPayment from "../fechData/FechGetReservationStatusPayment";

import "react-datepicker/dist/react-datepicker.css";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

class ClubReservationOnlineConfirmPaymentPage extends React.Component {
  constructor(props) {
    super(props);
  }

  day = "";
  court = "";
  hour = "";
  spiner = (
    <>
      <p>
        Oczekiwanie na potwierdzenie płatności
        <br />
      </p>
      <Spinner
        style={{ borderStyle: "dotted", marginLeft: "50%" }}
        animation="border"
      />
    </>
  );
  response = "";

  state = {
    startDate: new Date(),
    dataClubInfo: [],
    restClubData: {
      successful: false,
      status: "",
      message: "",
      data: [],
    },
    restReservationStatusData: {
      successful: false,
      status: "",
      message: "",
      data: [],
    },
  };

  async componentDidMount() {
    document.title = "Rezerwacja kortu - RezerwujKort.pl";
    registerLocale("pl", pl); // register it with the name you want
    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.day = this.urlValue.day;
    this.court = this.urlValue.court;
    this.hour = this.urlValue.hour;
    const values = new Map();
    if (this.day !== "") values.set("day", this.day);
    if (this.court !== "") values.set("court", this.court);
    if (this.hour !== "") values.set("hour", this.hour);

    await delay(2000);
    this.setState({
      restReservationStatusData: await FechGetReservationStatusPayment(
        this.props.match.params.token
      ),
    });

    if (
      this.state.restReservationStatusData.successful &&
      this.state.restReservationStatusData.data.payed === false
    ) {
      for (let i = 0; i < 10; i++) {
        await delay(3000);
        this.setState({
          restReservationStatusData: await FechGetReservationStatusPayment(
            this.props.match.params.token
          ),
        });
        if (
          this.state.restReservationStatusData.successful &&
          this.state.restReservationStatusData.data.status != null &&
          this.state.restReservationStatusData.data.status != ""
        ) {
          break;
        }
      }
    }
  }

  render() {
    if (
      this.state.restReservationStatusData.successful &&
      this.state.restReservationStatusData.data.status != null &&
      this.state.restReservationStatusData.data.status != ""
    ) {
      this.response = this.state.restReservationStatusData.data.status;
    }

    return (
      <div>
        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage tennis-center">
              {/* <div class="head">
                                <img src={`/images/login.png`} alt="" />
                                <p>Korty Tenisowe</p>
                            </div> */}
              <div class="main-subpage-content">
                {/* {beltClub} */}

                <div class="tenis-center-content">
                  {/* <ss dangerouslySetInnerHTML={this.printAdresse()} /> */}

                  <div className="tennis-center-coach">
                    {this.response && this.response !== "" ? (
                      this.response.split('<br />').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))
                    ) : (
                      <div className="tennis-center-coach">{this.spiner}</div>
                    )}
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>
        <div style={{ clear: "both" }}></div>

        <Footer />
      </div>
    );
  }
}

export default ClubReservationOnlineConfirmPaymentPage;
